// import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/js/fontawesome";
// import "@fortawesome/fontawesome-free/js/regular";
// import "@fortawesome/fontawesome-free/js/solid";
// import "@fortawesome/fontawesome-free/js/brands";
import { config, library, icon, dom } from '@fortawesome/fontawesome-svg-core'

config.mutateApproach = 'sync'

import {
  faBars,
  faList,
  faLock,
  faUnlock,
  faX,
  faEllipsisH,
  faEllipsisVertical,
  faUserSecret,
  faCaretUp,
  faCaretDown,
  faArchive,
  faPencilAlt,
  faTrash,
  faTrashAlt,
  faRedoAlt,
  faPlus,
  faPlusCircle,
  faHeadphonesAlt,
  faHeadset,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faArrowDown,
  faArrowLeft,
  faChevronCircleLeft,
  faChevronCircleRight,
  faTimes,
  faDesktop,
  faVideo,
  faSearch,
  faSmile,
  faQuestion,
  faRobot,
  faStickyNote,
  faClock,
  faBolt,
  faUserCircle,
  faSignOutAlt,
  faGlobeAmericas,
  faKey,
  faCircleInfo,
  faDownload,
  faRss,
  faSignInAlt,
  faMobileAlt,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import {
  faStar,
  faCopy,
  faQuestionCircle
} from '@fortawesome/free-regular-svg-icons'
import {
  faTwitter,
  faFirefox,
  faChrome,
  faSafari,
  faEdge,
  faEdgeLegacy,
  faApple,
  faAndroid,
  faGoogle,
  faFacebook,
  faInstagram,
  faInternetExplorer
} from '@fortawesome/free-brands-svg-icons'

import { faHeart as fasFaHeart } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farFaHeart } from '@fortawesome/free-regular-svg-icons'

library.add(
  fasFaHeart,
  farFaHeart,
  faBars,
  faList,
  faLock,
  faUnlock,
  faX,
  faEllipsisH,
  faEllipsisVertical,
  faUserSecret,
  faCaretUp,
  faCaretDown,
  faArchive,
  faPencilAlt,
  faTrash,
  faTrashAlt,
  faRedoAlt,
  faPlus,
  faPlusCircle,
  faHeadphonesAlt,
  faHeadset,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faArrowDown,
  faArrowLeft,
  faChevronCircleLeft,
  faChevronCircleRight,
  faTimes,
  faDesktop,
  faVideo,
  faSearch,
  faSmile,
  faQuestion,
  faRobot,
  faStickyNote,
  faClock,
  faBolt,
  faUserCircle,
  faSignOutAlt,
  faGlobeAmericas,
  faKey,
  faCircleInfo,
  faDownload,
  faRss,
  faSignInAlt,
  faMobileAlt,
  faStar,
  faCopy,
  faQuestionCircle,
  faTwitter,
  faFirefox,
  faChrome,
  faSafari,
  faEdge,
  faEdgeLegacy,
  faApple,
  faAndroid,
  faGoogle,
  faFacebook,
  faInstagram,
  faInternetExplorer,
  faCheck
)

dom.watch()
